<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-array"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-array">
    <span
      v-for="(item, index) in items"
      :key="index"
      :class="{'block': rowField.switch.columnView}"
    >
      <span v-if="!rowField.switch.max || index < rowField.switch.max">
        {{ index > 0 && !rowField.switch.columnView ? ', ' : '' }}{{ getLabel(item) }}
      </span>
    </span>
    <span v-if="items.length > rowField.switch.max" class="relative">...&nbsp;
      <span @click.stop="toggleList" class="text-blue-500 cursor-pointer">(+{{ items.length - rowField.switch.max }})</span>
      <div
        v-show="isOpen"
        class="drop-menu bg-white p-4 absolute rounded top-0 left-0 text-left border z-20"
      >
        <span
          v-for="(item, index) in items"
          :key="index"
        >
          <div v-if="index >= rowField.switch.max">{{ getLabel(item) }}</div>
        </span>
      </div>
    </span>
  </td>
</template>

<script>

import VuetableFieldMixin from "vue3-vuetable/src/components/VuetableFieldMixin";
import EventBus from "@/utils/EventBus";

export default {
  name: "ArrayField",
  mixins: [VuetableFieldMixin],
  data: () => {
    return {
      isOpen: false,
    }
  },
  computed: {
    items() {
      const {switch: {source}} = this.rowField;

      return this.rowData[source] || [];
    }
  },
  methods: {
    getLabel(item) {
      const {switch: {label}} = this.rowField;

      return item[label];
    },
    toggleList(e) {
      this.isOpen = !this.isOpen;
      EventBus.emit('array-field-list-toggle', e);
      e.stopPropagation();
    },
    handleOutsideClickEvent(e) {
      if (this.$el.contains(e.target)) {
        return;
      }
      this.close();
    },
    close() {
      this.isOpen = false;
    }
  },
  mounted() {
    EventBus.on('array-field-list-toggle', this.handleOutsideClickEvent);
    window.addEventListener('click', this.handleOutsideClickEvent);
  }
}
</script>

<style scoped>
.drop-menu {
  min-width: 13.85rem;
}
</style>
