<template>
  <List
    title="customer users"
    resource="appCustomerUsers"
    :basePath="basePath"
    :fields="fields"
    track-by="profileId"
    :request-params="{appId, appAccessId, nonDeleted: true}"
    ref="list"
    on-row-click="edit"
    :search="true"
    search-placeholder="search for a name and login"
    :initial-filter="filter"
    :filter-mapper="mapFilterToQuery"
  >
    <template v-slot:filters="props">
      <div class="form-row">
        <div class="form-col">
          <SelectInput
            name="roleId"
            label="role"
            :options="roles"
          />
        </div>
        <div class="form-col">
          <DateRangeInput
            label="registered at"
            name="registration"
            :max-date="today"
            position="right"
          />
        </div>
      </div>
      <div class="form-row justify-end">
        <button @click.stop="props.reset()" class="btn-primary btn-transparent mr-8">reset</button>
        <button class="btn-primary">filter</button>
      </div>
    </template>
  </List>
</template>

<script>
  import {get} from 'lodash-es';
  import List from "@/components/auth/list/List";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ModalNavigation from "@/mixins/ModalNavigation";
  import DateRangeFilterMixin from '@/components/auth/DateRangeFilterMixin';
  import UserField from "@/components/auth/list/fields/UserField";
  import DateTimeField from "@/components/auth/list/fields/DateTimeField";
  import DateRangeInput from "@/components/form/DateRangeInput";
  import ArrayField from "@/components/auth/list/fields/ArrayField";
  import SelectInput from "@/components/form/SelectInput";

export default {
    name: "AppCustomerUsersList",
    components: {
      SelectInput,
      List,
      DateRangeInput,
    },
    mixins: [ModalNavigation, NotifyMixin, DateRangeFilterMixin],
    data: function () {
      return {
        roles: [],
        filter: {
          roleId: undefined,
          registration: {
            startDate: null,
            endDate: null,
          },
        },
        fields: [
          {
            name: UserField,
            title: 'user name',
            sortField: 'profileName',
            switch: {
              getName: rowData => rowData.profileFullName,
              getEmail: rowData => rowData.profileEmail,
            }
          },
          {
            name: 'appRole.alias',
            title: 'user role',
            sortField: 'profileRole',
          },
          {
            name: ArrayField,
            title: 'apartment community',
            switch: {
              max: 1,
              source: 'communities',
              label: 'communityName',
            }
          },
          {
            name: DateTimeField,
            sortField: 'registrationDate',
            title: 'registration date',
            switch: {
              source: 'registrationDate'
            }
          },
        ],
      };
    },
    computed: {
      basePath: function () {
        return this.$route.path;
      },
      appId: function () {
        return this.$route.params.appId;
      },
      appAccessId: function () {
        return this.$route.params.appAccessId;
      },
      customerId: function () {
        return this.$route.params.customerId;
      }
    },
    mounted() {
      this.$authDataProvider.getList('customerRoles', {customerId: this.customerId, appId: this.appId})
        .then(({content: roles}) => {
          this.roles = roles.map(({alias, roleId: key, appName}) => ({
            value: `${appName}: ${alias}`,
            key
          }));
        })
        .catch((err) => this.notifyError(err));

      this.$authDataProvider.getOne('customers', {id: this.customerId})
        .then(customer => {
          this.setActiveModalTitle(`${customer.name} company users`);
          this.setActiveModalBreadcrumbName(`${customer.name} users`);
        })
        .catch(error => this.notifyError(error.message));
    },
    methods: {
      mapFilterToQuery(values) {
        const { from: registrationFrom, to: registrationTo } = this.getFilterDateRange(values.registration);

        return {
          roleId: get(values, 'roleId'),
          registrationFrom,
          registrationTo,
        };
      }
    }
  }
</script>

<style scoped>

</style>
